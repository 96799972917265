import { Component, Input, OnChanges, SimpleChanges, OnInit, EventEmitter, Output } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CursoSessaoDialogComponent } from './dialog/dialog.component';

@Component({
  selector: 'app-editor-custom-component-curso-sessao',
  templateUrl: 'curso-sessao.component.html'
})
export class CursoSessaoComponent implements OnInit, OnChanges {
  @Input() item: any = {};
  @Input() setting: any;
  @Input() full: boolean;
  @Input() screen: string;

  @Output() public removeItemEvent = new EventEmitter<any>();

  filtered: any;
  dataByFolder: any;
  dataByFolderBuffer: any;
  mock: any;
  sessions: any;

  data: any;

  owlOptions = { items: 1, loop: false, dots: false, navigation: false, autoplay: true, autoplayTimeout: 5000, autoplayHoverPause: true };

  constructor(
    public dataService: DataService,
    public dialog: MatDialog
  ) {
    this.mock = {
      arquivo: 'assets/img/no-image.jpg',
      titulo: 'Lorem ipsum dolor sit amet',
      descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac ligula eget dolor aliquam commodo.',
      andamento: 30
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes', changes);
  }

  ngOnInit() {
    // if (!this.setting.session_id) {
    //   this.openDialog();
    // } else {
    // }
    this.getCustom();
    this.getData();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CursoSessaoDialogComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.item.title.text = result.name;
        this.item.session_id = result.id;

        this.getData();
        this.getCustom();
      } else {
        this.removeItemEvent.emit();
        console.log('openDialog: remove curso');
      }
    });
  }

  getData() {
    const setting = this.setting;
    setting.itens = 6;
    setting.folder = false;
    this.dataService.geralWeb({ main: [setting] })
      .subscribe((res: any) => {
        this.data = res.main[0].itens;
        this.filtered = this.data;
      });
  }

  getCustom() {
    const setting = this.setting;
    setting.itens = 6;
    setting.folder = true;
    this.dataService.geralWeb({ main: [setting] })
      .subscribe((res: any) => {
        this.dataByFolder = res.main[0].itens;
      });
  }
}

